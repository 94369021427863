import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import InformationSection from '../../components/InformationSection';
import TestimonialSection from '../../components/TestimonialSection';
import PortfolioTiles from '../../components/PortfolioTiles';
import ProjectFoot from '../../components/ProjectFoot';
import PageHelmet from '../../components/PageHelmet';
import DualSlider from '../../components/DualSlider';
import HeroSlider from '../../components/HeroSlider';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const AssetManagementTemplate = ({
  heroImages,
  informationSection,
  seoObject,
  dualslider1,
  dualslider2,
  isPreview,
  projectFoot,
  testimonial,
  helmet,
}) => (
  <>
    {helmet || ''}
    <PageHelmet seoObject={seoObject} />
    <HeroSlider heroImages={heroImages} heroTitle='' />
    <InformationSection informationSection={informationSection} />
    {!!dualslider1 ? (
      <DualSlider slides={dualslider1} triangle='top-right' />
    ) : null}
    <TestimonialSection testimonial={testimonial} />
    {!!dualslider2 ? (
      <DualSlider slides={dualslider2} triangle='top-right' />
    ) : null}
    {!!dualslider2 ? null : <div className='spacer'></div>}
    <PortfolioTiles locale='en' isPreview={isPreview} />
    <ProjectFoot projectFoot={projectFoot} />
  </>
);

const AssetManagementPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <AssetManagementTemplate
        heroImages={frontmatter.heroImages}
        informationSection={frontmatter.informationSection}
        seoObject={frontmatter.seoObject}
        dualslider1={frontmatter.dualslider_one}
        testimonial={frontmatter.testimonial}
        dualslider2={frontmatter.dualslider_two}
        projectFoot={frontmatter.projectFoot}
        location={location}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImages
              .slice(0, 1)
              .map((item) => item.image)}`}
          />
        }
      />
    </Layout>
  );
};

AssetManagementPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AssetManagementPage;

export const pageQuery = graphql`
  query AssetManagementPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "asset-management-page-zhhans" } }
    ) {
      frontmatter {
        heroImages {
          image
          overlay
          heroTitle
          heroSubtitle
          animation
          position
          buttonObject {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
        }
        dualslider_one {
          dualsliderTitle
          slides {
            title
            image
            text
          }
        }
        dualslider_two {
          dualsliderTitle
          slides {
            title
            image
            text
          }
        }
        testimonial {
          review
          name
        }
        projectFoot {
          title
          text
          image
          overlay
          email
        }
        informationSection {
          color
          textItems {
            title
            text
          }
        }
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
